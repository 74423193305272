import { cn } from "@/utils/index";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  useV1ServiceV1OrganizationManagementMemberList,
  useV1ServiceV1UserManagementTokenOrganizationRefreshCreate,
  useV1ServiceV1UserManagementTokenRefreshCreate
} from "common/api/queries";
import useAuth from "hooks/useAuth";
import { LogOutIcon, MedalIcon, SettingsIcon, ShoppingBagIcon, UserCircle } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import { useUiStore } from "store/ui/ui";
import SwitchingWorkspaceModal from "./switching-workspace-modal";
import { TokenRefreshResponse } from "common/api/requests";
import { useOrderFormStore } from "store/order-form/order-form";
import { useRouter } from "next/router";
import { showToast } from "legend-ui";

export default function AccountDropdown() {
  const { accountDropdownOpen, setAccountDropdownOpen } = useUiStore();
  const auth = useAuth();
  const userId = auth.session?.userId;
  const { data: workspaces } = useV1ServiceV1OrganizationManagementMemberList(
    { page: 1, userId },
    undefined,
    { enabled: !!userId }
  );
  const currentWorkspace =
    workspaces?.results?.find((w) => w.id === auth.session?.workspaceId)?.id ?? "personal";
  const [switchingToWorkspace, setSwitchingToWorkspace] = useState<string>();
  const router = useRouter();
  const showWorkspaceSwitching = router.pathname !== "/create";
  const showPersonalWorkspace = auth.session?.adultUser;

  const handleWorkspaceChange = (workspaceId: string) => {
    if (workspaceId === currentWorkspace) return;
    const name = workspaceId === "personal" ? "Personal" : "Family";
    setAccountDropdownOpen(false);
    setSwitchingToWorkspace(name);

    if (workspaceId === "personal") {
      switchToPersonalWorkspace();
    } else {
      switchToOrgWorkspace({ organizationId: workspaceId });
    }
  };

  const handleSwitchSuccess = (response: TokenRefreshResponse) => {
    useOrderFormStore.getState().clear();
    auth.replaceAccessToken(response.access_token);
    showToast(`Switched to ${switchingToWorkspace} workspace`);
    setSwitchingToWorkspace(undefined);
  };
  const handleSwitchError = () => {
    showToast("We ran into an issue. Please try again.");
    setSwitchingToWorkspace(undefined);
  };

  const { mutate: switchToOrgWorkspace } =
    useV1ServiceV1UserManagementTokenOrganizationRefreshCreate({
      onSuccess: handleSwitchSuccess,
      onError: handleSwitchError
    });
  const { mutate: switchToPersonalWorkspace } = useV1ServiceV1UserManagementTokenRefreshCreate({
    onSuccess: handleSwitchSuccess,
    onError: handleSwitchError
  });

  return (
    <DropdownMenu.Root open={accountDropdownOpen}>
      <DropdownMenu.Trigger
        className="rounded-md p-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white md:px-3"
        type="button"
        onClick={() => setAccountDropdownOpen(!accountDropdownOpen)}
      >
        <UserCircle />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-30 w-44 rounded bg-white p-2 text-base shadow-md"
          align="end"
          onPointerDownOutside={() => setAccountDropdownOpen(false)}
        >
          {showWorkspaceSwitching && workspaces?.results?.length > 0 && (
            <>
              <DropdownMenu.Label className="p-1 text-xs text-gray-600">
                Workspaces
              </DropdownMenu.Label>
              <DropdownMenu.RadioGroup
                className="flex select-none flex-col"
                value={currentWorkspace}
                onValueChange={handleWorkspaceChange}
              >
                {showPersonalWorkspace && (
                  <DropdownMenu.RadioItem
                    className={cn("flex cursor-pointer rounded px-2 py-1 hover:bg-gray-300", {
                      "bg-gray-200 text-indigo-600": currentWorkspace === "personal"
                    })}
                    value="personal"
                  >
                    Personal
                  </DropdownMenu.RadioItem>
                )}
                {workspaces?.results?.map((workspace) => (
                  <DropdownMenu.RadioItem
                    key={workspace.id}
                    className={cn("flex cursor-pointer rounded px-2 py-1 hover:bg-gray-300", {
                      "bg-gray-200 text-indigo-600": currentWorkspace === workspace.id
                    })}
                    value={workspace.id}
                  >
                    {workspace.name}
                  </DropdownMenu.RadioItem>
                ))}
              </DropdownMenu.RadioGroup>
              <DropdownMenu.Separator className="my-1 border-t border-gray-300" />
            </>
          )}
          <Link href="/dashboard">
            <DropdownMenu.Item
              onClick={() => setAccountDropdownOpen(false)}
              className="flex cursor-pointer items-center gap-x-3 rounded px-2 py-1 hover:bg-gray-300"
            >
              <ShoppingBagIcon size={18} />
              <p>Dashboard</p>
            </DropdownMenu.Item>
          </Link>
          <Link href="/teams">
            <DropdownMenu.Item
              onClick={() => setAccountDropdownOpen(false)}
              className="flex cursor-pointer items-center gap-x-3 rounded px-2 py-1 hover:bg-gray-300"
            >
              <MedalIcon size={18} />
              <p>Teams</p>
            </DropdownMenu.Item>
          </Link>
          <Link href="/settings">
            <DropdownMenu.Item
              onClick={() => setAccountDropdownOpen(false)}
              className="flex cursor-pointer items-center gap-x-3 rounded px-2 py-1 hover:bg-gray-300"
            >
              <SettingsIcon size={18} />
              <p>Settings</p>
            </DropdownMenu.Item>
          </Link>
          <DropdownMenu.Separator className="my-1 border-t border-gray-300" />
          <Link href="/logout">
            <DropdownMenu.Item
              onClick={() => setAccountDropdownOpen(false)}
              className="flex cursor-pointer items-center gap-x-3 rounded px-2 py-1 hover:bg-gray-300"
            >
              <LogOutIcon size={18} />
              <p>Log out</p>
            </DropdownMenu.Item>
          </Link>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
      <SwitchingWorkspaceModal open={!!switchingToWorkspace} name={switchingToWorkspace} />
    </DropdownMenu.Root>
  );
}

// generated with @7nohe/openapi-react-query-codegen@1.4.0 

import { UseQueryResult } from "@tanstack/react-query";
import { V1Service } from "../requests/services.gen";
export type V1ServiceV1CommunicationEmailTemplateListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplateList>>;
export type V1ServiceV1CommunicationEmailTemplateListQueryResult<TData = V1ServiceV1CommunicationEmailTemplateListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CommunicationEmailTemplateListKey = "V1ServiceV1CommunicationEmailTemplateList";
export const UseV1ServiceV1CommunicationEmailTemplateListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CommunicationEmailTemplateListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CommunicationEmailTemplateReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplateRead>>;
export type V1ServiceV1CommunicationEmailTemplateReadQueryResult<TData = V1ServiceV1CommunicationEmailTemplateReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CommunicationEmailTemplateReadKey = "V1ServiceV1CommunicationEmailTemplateRead";
export const UseV1ServiceV1CommunicationEmailTemplateReadKeyFn = ({ emailTemplateId }: {
  emailTemplateId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CommunicationEmailTemplateReadKey, ...(queryKey ?? [{ emailTemplateId }])];
export type V1ServiceV1CommunicationTextTemplateListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplateList>>;
export type V1ServiceV1CommunicationTextTemplateListQueryResult<TData = V1ServiceV1CommunicationTextTemplateListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CommunicationTextTemplateListKey = "V1ServiceV1CommunicationTextTemplateList";
export const UseV1ServiceV1CommunicationTextTemplateListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CommunicationTextTemplateListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CommunicationTextTemplateReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplateRead>>;
export type V1ServiceV1CommunicationTextTemplateReadQueryResult<TData = V1ServiceV1CommunicationTextTemplateReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CommunicationTextTemplateReadKey = "V1ServiceV1CommunicationTextTemplateRead";
export const UseV1ServiceV1CommunicationTextTemplateReadKeyFn = ({ textTemplateId }: {
  textTemplateId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CommunicationTextTemplateReadKey, ...(queryKey ?? [{ textTemplateId }])];
export type V1ServiceUserSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.userSearchGet>>;
export type V1ServiceUserSearchGetQueryResult<TData = V1ServiceUserSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceUserSearchGetKey = "V1ServiceUserSearchGet";
export const UseV1ServiceUserSearchGetKeyFn = ({ limit, offset, term }: {
  limit?: number;
  offset?: number;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceUserSearchGetKey, ...(queryKey ?? [{ limit, offset, term }])];
export type V1ServiceInternalUserSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.internalUserSearchGet>>;
export type V1ServiceInternalUserSearchGetQueryResult<TData = V1ServiceInternalUserSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceInternalUserSearchGetKey = "V1ServiceInternalUserSearchGet";
export const UseV1ServiceInternalUserSearchGetKeyFn = ({ limit, offset, term }: {
  limit?: number;
  offset?: number;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceInternalUserSearchGetKey, ...(queryKey ?? [{ limit, offset, term }])];
export type V1ServiceV1UserManagementExternalServiceUsersListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementExternalServiceUsersList>>;
export type V1ServiceV1UserManagementExternalServiceUsersListQueryResult<TData = V1ServiceV1UserManagementExternalServiceUsersListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementExternalServiceUsersListKey = "V1ServiceV1UserManagementExternalServiceUsersList";
export const UseV1ServiceV1UserManagementExternalServiceUsersListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementExternalServiceUsersListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1UserManagementExternalServiceUsersReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementExternalServiceUsersRead>>;
export type V1ServiceV1UserManagementExternalServiceUsersReadQueryResult<TData = V1ServiceV1UserManagementExternalServiceUsersReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementExternalServiceUsersReadKey = "V1ServiceV1UserManagementExternalServiceUsersRead";
export const UseV1ServiceV1UserManagementExternalServiceUsersReadKeyFn = ({ userId }: {
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementExternalServiceUsersReadKey, ...(queryKey ?? [{ userId }])];
export type V1ServiceV1UserManagementPoliciesListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesList>>;
export type V1ServiceV1UserManagementPoliciesListQueryResult<TData = V1ServiceV1UserManagementPoliciesListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementPoliciesListKey = "V1ServiceV1UserManagementPoliciesList";
export const UseV1ServiceV1UserManagementPoliciesListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementPoliciesListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1UserManagementPoliciesCurrentListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesCurrentList>>;
export type V1ServiceV1UserManagementPoliciesCurrentListQueryResult<TData = V1ServiceV1UserManagementPoliciesCurrentListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementPoliciesCurrentListKey = "V1ServiceV1UserManagementPoliciesCurrentList";
export const UseV1ServiceV1UserManagementPoliciesCurrentListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementPoliciesCurrentListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1UserManagementPoliciesAccessListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesAccessList>>;
export type V1ServiceV1UserManagementPoliciesAccessListQueryResult<TData = V1ServiceV1UserManagementPoliciesAccessListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementPoliciesAccessListKey = "V1ServiceV1UserManagementPoliciesAccessList";
export const UseV1ServiceV1UserManagementPoliciesAccessListKeyFn = ({ page, policyName }: {
  page?: number;
  policyName: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementPoliciesAccessListKey, ...(queryKey ?? [{ page, policyName }])];
export type V1ServiceV1UserManagementPoliciesReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesRead>>;
export type V1ServiceV1UserManagementPoliciesReadQueryResult<TData = V1ServiceV1UserManagementPoliciesReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementPoliciesReadKey = "V1ServiceV1UserManagementPoliciesRead";
export const UseV1ServiceV1UserManagementPoliciesReadKeyFn = ({ versionId }: {
  versionId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementPoliciesReadKey, ...(queryKey ?? [{ versionId }])];
export type V1ServiceV1UserManagementServiceUserAccountListGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementServiceUserAccountListGet>>;
export type V1ServiceV1UserManagementServiceUserAccountListGetQueryResult<TData = V1ServiceV1UserManagementServiceUserAccountListGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementServiceUserAccountListGetKey = "V1ServiceV1UserManagementServiceUserAccountListGet";
export const UseV1ServiceV1UserManagementServiceUserAccountListGetKeyFn = ({ active, adultUser, created, createdGt, createdLt, dataRetentionStatus, dataRetentionStatusIn, dataRetentionStatusNot, dataRetentionStatusNotIn, marketingStatus, marketingStatusIn, marketingStatusNot, marketingStatusNotIn, page, pageSize, userType, userTypeIn, userTypeNot, userTypeNotIn }: {
  active?: string;
  adultUser?: string;
  created?: string;
  createdGt?: string;
  createdLt?: string;
  dataRetentionStatus?: string;
  dataRetentionStatusIn?: string;
  dataRetentionStatusNot?: string;
  dataRetentionStatusNotIn?: string;
  marketingStatus?: string;
  marketingStatusIn?: string;
  marketingStatusNot?: string;
  marketingStatusNotIn?: string;
  page?: number;
  pageSize?: number;
  userType?: string;
  userTypeIn?: string;
  userTypeNot?: string;
  userTypeNotIn?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementServiceUserAccountListGetKey, ...(queryKey ?? [{ active, adultUser, created, createdGt, createdLt, dataRetentionStatus, dataRetentionStatusIn, dataRetentionStatusNot, dataRetentionStatusNotIn, marketingStatus, marketingStatusIn, marketingStatusNot, marketingStatusNotIn, page, pageSize, userType, userTypeIn, userTypeNot, userTypeNotIn }])];
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspaceUserPiiDataEntryList>>;
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListQueryResult<TData = V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListKey = "V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryList";
export const UseV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspaceUserPiiDataEntryRead>>;
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadQueryResult<TData = V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadKey = "V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryRead";
export const UseV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadKeyFn = ({ userPiiDataEntryId, workspaceId }: {
  userPiiDataEntryId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryReadKey, ...(queryKey ?? [{ userPiiDataEntryId, workspaceId }])];
export type V1ServiceV1UserManagementUsersListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersList>>;
export type V1ServiceV1UserManagementUsersListQueryResult<TData = V1ServiceV1UserManagementUsersListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUsersListKey = "V1ServiceV1UserManagementUsersList";
export const UseV1ServiceV1UserManagementUsersListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUsersListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1UserManagementUsersReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersRead>>;
export type V1ServiceV1UserManagementUsersReadQueryResult<TData = V1ServiceV1UserManagementUsersReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUsersReadKey = "V1ServiceV1UserManagementUsersRead";
export const UseV1ServiceV1UserManagementUsersReadKeyFn = ({ userId }: {
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUsersReadKey, ...(queryKey ?? [{ userId }])];
export type V1ServiceV1UserManagementUsersUserPiiDataEntryListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryList>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryListQueryResult<TData = V1ServiceV1UserManagementUsersUserPiiDataEntryListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUsersUserPiiDataEntryListKey = "V1ServiceV1UserManagementUsersUserPiiDataEntryList";
export const UseV1ServiceV1UserManagementUsersUserPiiDataEntryListKeyFn = ({ page, userId }: {
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUsersUserPiiDataEntryListKey, ...(queryKey ?? [{ page, userId }])];
export type V1ServiceV1UserManagementUsersUserPiiDataEntryReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryRead>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryReadQueryResult<TData = V1ServiceV1UserManagementUsersUserPiiDataEntryReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1UserManagementUsersUserPiiDataEntryReadKey = "V1ServiceV1UserManagementUsersUserPiiDataEntryRead";
export const UseV1ServiceV1UserManagementUsersUserPiiDataEntryReadKeyFn = ({ userId, userPiiDataEntryId }: {
  userId: string;
  userPiiDataEntryId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1UserManagementUsersUserPiiDataEntryReadKey, ...(queryKey ?? [{ userId, userPiiDataEntryId }])];
export type V1ServiceV1OrganizationManagementInviteReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementInviteRead>>;
export type V1ServiceV1OrganizationManagementInviteReadQueryResult<TData = V1ServiceV1OrganizationManagementInviteReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementInviteReadKey = "V1ServiceV1OrganizationManagementInviteRead";
export const UseV1ServiceV1OrganizationManagementInviteReadKeyFn = ({ inviteId }: {
  inviteId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementInviteReadKey, ...(queryKey ?? [{ inviteId }])];
export type V1ServiceV1OrganizationManagementMemberListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementMemberList>>;
export type V1ServiceV1OrganizationManagementMemberListQueryResult<TData = V1ServiceV1OrganizationManagementMemberListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementMemberListKey = "V1ServiceV1OrganizationManagementMemberList";
export const UseV1ServiceV1OrganizationManagementMemberListKeyFn = ({ page, userId }: {
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementMemberListKey, ...(queryKey ?? [{ page, userId }])];
export type V1ServiceV1OrganizationManagementOrganizationListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationList>>;
export type V1ServiceV1OrganizationManagementOrganizationListQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationListKey = "V1ServiceV1OrganizationManagementOrganizationList";
export const UseV1ServiceV1OrganizationManagementOrganizationListKeyFn = ({ excludeTags, includeTags, page, tags }: {
  excludeTags?: string;
  includeTags?: string;
  page?: number;
  tags?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationListKey, ...(queryKey ?? [{ excludeTags, includeTags, page, tags }])];
export type V1ServiceV1OrganizationManagementOrganizationMemberIdGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberIdGet>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberIdGetQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationMemberIdGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationMemberIdGetKey = "V1ServiceV1OrganizationManagementOrganizationMemberIdGet";
export const UseV1ServiceV1OrganizationManagementOrganizationMemberIdGetKeyFn = ({ organizationMemberId }: {
  organizationMemberId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationMemberIdGetKey, ...(queryKey ?? [{ organizationMemberId }])];
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryList>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListKey = "V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryList";
export const UseV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryRead>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadKey = "V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryRead";
export const UseV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadKeyFn = ({ organizationPiiDataEntryId, workspaceId }: {
  organizationPiiDataEntryId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryReadKey, ...(queryKey ?? [{ organizationPiiDataEntryId, workspaceId }])];
export type V1ServiceV1OrganizationManagementOrganizationReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationRead>>;
export type V1ServiceV1OrganizationManagementOrganizationReadQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationReadKey = "V1ServiceV1OrganizationManagementOrganizationRead";
export const UseV1ServiceV1OrganizationManagementOrganizationReadKeyFn = ({ organizationId }: {
  organizationId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationReadKey, ...(queryKey ?? [{ organizationId }])];
export type V1ServiceV1OrganizationManagementOrganizationInviteListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInviteList>>;
export type V1ServiceV1OrganizationManagementOrganizationInviteListQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationInviteListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationInviteListKey = "V1ServiceV1OrganizationManagementOrganizationInviteList";
export const UseV1ServiceV1OrganizationManagementOrganizationInviteListKeyFn = ({ organizationId, page }: {
  organizationId: string;
  page?: number;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationInviteListKey, ...(queryKey ?? [{ organizationId, page }])];
export type V1ServiceV1OrganizationManagementOrganizationInviteReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInviteRead>>;
export type V1ServiceV1OrganizationManagementOrganizationInviteReadQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationInviteReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationInviteReadKey = "V1ServiceV1OrganizationManagementOrganizationInviteRead";
export const UseV1ServiceV1OrganizationManagementOrganizationInviteReadKeyFn = ({ inviteId, organizationId }: {
  inviteId: string;
  organizationId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationInviteReadKey, ...(queryKey ?? [{ inviteId, organizationId }])];
export type V1ServiceV1OrganizationManagementOrganizationMemberListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberList>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberListQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationMemberListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationMemberListKey = "V1ServiceV1OrganizationManagementOrganizationMemberList";
export const UseV1ServiceV1OrganizationManagementOrganizationMemberListKeyFn = ({ organizationId, page }: {
  organizationId: string;
  page?: number;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationMemberListKey, ...(queryKey ?? [{ organizationId, page }])];
export type V1ServiceV1OrganizationManagementOrganizationMemberReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberRead>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberReadQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationMemberReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationMemberReadKey = "V1ServiceV1OrganizationManagementOrganizationMemberRead";
export const UseV1ServiceV1OrganizationManagementOrganizationMemberReadKeyFn = ({ organizationId, userId }: {
  organizationId: string;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationMemberReadKey, ...(queryKey ?? [{ organizationId, userId }])];
export type V1ServiceV1OrganizationManagementOrganizationMemberGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberGet>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberGetQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationMemberGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationMemberGetKey = "V1ServiceV1OrganizationManagementOrganizationMemberGet";
export const UseV1ServiceV1OrganizationManagementOrganizationMemberGetKeyFn = ({ organizationId, page, userId }: {
  organizationId: string;
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationMemberGetKey, ...(queryKey ?? [{ organizationId, page, userId }])];
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOrganizationPiiDataEntryList>>;
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListKey = "V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryList";
export const UseV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListKeyFn = ({ organizationId, page }: {
  organizationId: string;
  page?: number;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryListKey, ...(queryKey ?? [{ organizationId, page }])];
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOrganizationPiiDataEntryRead>>;
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadQueryResult<TData = V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadKey = "V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryRead";
export const UseV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadKeyFn = ({ organizationId, organizationPiiDataEntryId }: {
  organizationId: string;
  organizationPiiDataEntryId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryReadKey, ...(queryKey ?? [{ organizationId, organizationPiiDataEntryId }])];
export type V1ServiceV1OrganizationManagementUserPermissionListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementUserPermissionList>>;
export type V1ServiceV1OrganizationManagementUserPermissionListQueryResult<TData = V1ServiceV1OrganizationManagementUserPermissionListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrganizationManagementUserPermissionListKey = "V1ServiceV1OrganizationManagementUserPermissionList";
export const UseV1ServiceV1OrganizationManagementUserPermissionListKeyFn = ({ page, userId }: {
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrganizationManagementUserPermissionListKey, ...(queryKey ?? [{ page, userId }])];
export type V1ServiceOrganizationInternalSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.organizationInternalSearchGet>>;
export type V1ServiceOrganizationInternalSearchGetQueryResult<TData = V1ServiceOrganizationInternalSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceOrganizationInternalSearchGetKey = "V1ServiceOrganizationInternalSearchGet";
export const UseV1ServiceOrganizationInternalSearchGetKeyFn = ({ excludeTags, includeTags, limit, offset, tags, term }: {
  excludeTags?: string;
  includeTags?: string;
  limit?: number;
  offset?: number;
  tags?: string;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceOrganizationInternalSearchGetKey, ...(queryKey ?? [{ excludeTags, includeTags, limit, offset, tags, term }])];
export type V1ServiceOrganizationSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.organizationSearchGet>>;
export type V1ServiceOrganizationSearchGetQueryResult<TData = V1ServiceOrganizationSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceOrganizationSearchGetKey = "V1ServiceOrganizationSearchGet";
export const UseV1ServiceOrganizationSearchGetKeyFn = ({ excludeTags, includeTags, limit, offset, tags, term }: {
  excludeTags?: string;
  includeTags?: string;
  limit?: number;
  offset?: number;
  tags?: string;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceOrganizationSearchGetKey, ...(queryKey ?? [{ excludeTags, includeTags, limit, offset, tags, term }])];
export type V1ServiceV1ApiUserApiUserByUserIdReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1ApiUserApiUserByUserIdRead>>;
export type V1ServiceV1ApiUserApiUserByUserIdReadQueryResult<TData = V1ServiceV1ApiUserApiUserByUserIdReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1ApiUserApiUserByUserIdReadKey = "V1ServiceV1ApiUserApiUserByUserIdRead";
export const UseV1ServiceV1ApiUserApiUserByUserIdReadKeyFn = ({ userId }: {
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1ApiUserApiUserByUserIdReadKey, ...(queryKey ?? [{ userId }])];
export type V1ServiceV1ApiUserOrganizationListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1ApiUserOrganizationList>>;
export type V1ServiceV1ApiUserOrganizationListQueryResult<TData = V1ServiceV1ApiUserOrganizationListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1ApiUserOrganizationListKey = "V1ServiceV1ApiUserOrganizationList";
export const UseV1ServiceV1ApiUserOrganizationListKeyFn = ({ organizationId, page }: {
  organizationId: string;
  page?: number;
}, queryKey?: Array<unknown>) => [useV1ServiceV1ApiUserOrganizationListKey, ...(queryKey ?? [{ organizationId, page }])];
export type V1ServiceV1ApiUserOrganizationApiUserReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1ApiUserOrganizationApiUserRead>>;
export type V1ServiceV1ApiUserOrganizationApiUserReadQueryResult<TData = V1ServiceV1ApiUserOrganizationApiUserReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1ApiUserOrganizationApiUserReadKey = "V1ServiceV1ApiUserOrganizationApiUserRead";
export const UseV1ServiceV1ApiUserOrganizationApiUserReadKeyFn = ({ apiUserId, organizationId }: {
  apiUserId: string;
  organizationId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1ApiUserOrganizationApiUserReadKey, ...(queryKey ?? [{ apiUserId, organizationId }])];
export type V1ServiceV1CardJamCardListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardList>>;
export type V1ServiceV1CardJamCardListQueryResult<TData = V1ServiceV1CardJamCardListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardListKey = "V1ServiceV1CardJamCardList";
export const UseV1ServiceV1CardJamCardListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamCardPackListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardPackList>>;
export type V1ServiceV1CardJamCardPackListQueryResult<TData = V1ServiceV1CardJamCardPackListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardPackListKey = "V1ServiceV1CardJamCardPackList";
export const UseV1ServiceV1CardJamCardPackListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardPackListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamCardPackReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardPackRead>>;
export type V1ServiceV1CardJamCardPackReadQueryResult<TData = V1ServiceV1CardJamCardPackReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardPackReadKey = "V1ServiceV1CardJamCardPackRead";
export const UseV1ServiceV1CardJamCardPackReadKeyFn = ({ cardPackId }: {
  cardPackId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardPackReadKey, ...(queryKey ?? [{ cardPackId }])];
export type V1ServiceV1CardJamCardSetListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetList>>;
export type V1ServiceV1CardJamCardSetListQueryResult<TData = V1ServiceV1CardJamCardSetListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardSetListKey = "V1ServiceV1CardJamCardSetList";
export const UseV1ServiceV1CardJamCardSetListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardSetListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamCardSetPackListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackList>>;
export type V1ServiceV1CardJamCardSetPackListQueryResult<TData = V1ServiceV1CardJamCardSetPackListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardSetPackListKey = "V1ServiceV1CardJamCardSetPackList";
export const UseV1ServiceV1CardJamCardSetPackListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardSetPackListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackCardSetCardSetPackTypeList>>;
export type V1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListQueryResult<TData = V1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListKey = "V1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeList";
export const UseV1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListKeyFn = ({ cardSetId, cardSetPackType, page }: {
  cardSetId: string;
  cardSetPackType: string;
  page?: number;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardSetPackCardSetCardSetPackTypeListKey, ...(queryKey ?? [{ cardSetId, cardSetPackType, page }])];
export type V1ServiceV1CardJamCardSetPackReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackRead>>;
export type V1ServiceV1CardJamCardSetPackReadQueryResult<TData = V1ServiceV1CardJamCardSetPackReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardSetPackReadKey = "V1ServiceV1CardJamCardSetPackRead";
export const UseV1ServiceV1CardJamCardSetPackReadKeyFn = ({ cardSetPackId }: {
  cardSetPackId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardSetPackReadKey, ...(queryKey ?? [{ cardSetPackId }])];
export type V1ServiceV1CardJamCardSetReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetRead>>;
export type V1ServiceV1CardJamCardSetReadQueryResult<TData = V1ServiceV1CardJamCardSetReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardSetReadKey = "V1ServiceV1CardJamCardSetRead";
export const UseV1ServiceV1CardJamCardSetReadKeyFn = ({ cardSetId }: {
  cardSetId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardSetReadKey, ...(queryKey ?? [{ cardSetId }])];
export type V1ServiceV1CardJamCardVariantAttributeListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeList>>;
export type V1ServiceV1CardJamCardVariantAttributeListQueryResult<TData = V1ServiceV1CardJamCardVariantAttributeListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardVariantAttributeListKey = "V1ServiceV1CardJamCardVariantAttributeList";
export const UseV1ServiceV1CardJamCardVariantAttributeListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardVariantAttributeListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamCardVariantAttributeReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeRead>>;
export type V1ServiceV1CardJamCardVariantAttributeReadQueryResult<TData = V1ServiceV1CardJamCardVariantAttributeReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardVariantAttributeReadKey = "V1ServiceV1CardJamCardVariantAttributeRead";
export const UseV1ServiceV1CardJamCardVariantAttributeReadKeyFn = ({ cardVariantAttribute }: {
  cardVariantAttribute: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardVariantAttributeReadKey, ...(queryKey ?? [{ cardVariantAttribute }])];
export type V1ServiceV1CardJamMarketingCampaignListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignList>>;
export type V1ServiceV1CardJamMarketingCampaignListQueryResult<TData = V1ServiceV1CardJamMarketingCampaignListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamMarketingCampaignListKey = "V1ServiceV1CardJamMarketingCampaignList";
export const UseV1ServiceV1CardJamMarketingCampaignListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamMarketingCampaignListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1CardJamMarketingCampaignDetailGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignDetailGet>>;
export type V1ServiceV1CardJamMarketingCampaignDetailGetQueryResult<TData = V1ServiceV1CardJamMarketingCampaignDetailGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamMarketingCampaignDetailGetKey = "V1ServiceV1CardJamMarketingCampaignDetailGet";
export const UseV1ServiceV1CardJamMarketingCampaignDetailGetKeyFn = ({ marketingCampaignId }: {
  marketingCampaignId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamMarketingCampaignDetailGetKey, ...(queryKey ?? [{ marketingCampaignId }])];
export type V1ServiceV1CardJamWorkspaceCardListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardList>>;
export type V1ServiceV1CardJamWorkspaceCardListQueryResult<TData = V1ServiceV1CardJamWorkspaceCardListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardListKey = "V1ServiceV1CardJamWorkspaceCardList";
export const UseV1ServiceV1CardJamWorkspaceCardListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceCardAttributeListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardAttributeList>>;
export type V1ServiceV1CardJamWorkspaceCardAttributeListQueryResult<TData = V1ServiceV1CardJamWorkspaceCardAttributeListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardAttributeListKey = "V1ServiceV1CardJamWorkspaceCardAttributeList";
export const UseV1ServiceV1CardJamWorkspaceCardAttributeListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardAttributeListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1CardJamCardAttributeGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardAttributeGet>>;
export type V1ServiceV1CardJamCardAttributeGetQueryResult<TData = V1ServiceV1CardJamCardAttributeGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardAttributeGetKey = "V1ServiceV1CardJamCardAttributeGet";
export const UseV1ServiceV1CardJamCardAttributeGetKeyFn = ({ cardAttributeId, workspaceId }: {
  cardAttributeId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardAttributeGetKey, ...(queryKey ?? [{ cardAttributeId, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceCardDesignListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardDesignList>>;
export type V1ServiceV1CardJamWorkspaceCardDesignListQueryResult<TData = V1ServiceV1CardJamWorkspaceCardDesignListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardDesignListKey = "V1ServiceV1CardJamWorkspaceCardDesignList";
export const UseV1ServiceV1CardJamWorkspaceCardDesignListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardDesignListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1CardJamCardDesignGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamCardDesignGet>>;
export type V1ServiceV1CardJamCardDesignGetQueryResult<TData = V1ServiceV1CardJamCardDesignGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamCardDesignGetKey = "V1ServiceV1CardJamCardDesignGet";
export const UseV1ServiceV1CardJamCardDesignGetKeyFn = ({ cardDesignId, workspaceId }: {
  cardDesignId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamCardDesignGetKey, ...(queryKey ?? [{ cardDesignId, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceCardSetListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardSetList>>;
export type V1ServiceV1CardJamWorkspaceCardSetListQueryResult<TData = V1ServiceV1CardJamWorkspaceCardSetListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardSetListKey = "V1ServiceV1CardJamWorkspaceCardSetList";
export const UseV1ServiceV1CardJamWorkspaceCardSetListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardSetListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceCardSetGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardSetGet>>;
export type V1ServiceV1CardJamWorkspaceCardSetGetQueryResult<TData = V1ServiceV1CardJamWorkspaceCardSetGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardSetGetKey = "V1ServiceV1CardJamWorkspaceCardSetGet";
export const UseV1ServiceV1CardJamWorkspaceCardSetGetKeyFn = ({ cardSetId, workspaceId }: {
  cardSetId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardSetGetKey, ...(queryKey ?? [{ cardSetId, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceCardReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardRead>>;
export type V1ServiceV1CardJamWorkspaceCardReadQueryResult<TData = V1ServiceV1CardJamWorkspaceCardReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceCardReadKey = "V1ServiceV1CardJamWorkspaceCardRead";
export const UseV1ServiceV1CardJamWorkspaceCardReadKeyFn = ({ cardId, workspaceId }: {
  cardId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceCardReadKey, ...(queryKey ?? [{ cardId, workspaceId }])];
export type V1ServiceV1CardJamWorkspaceUnopenedCardPackListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceUnopenedCardPackList>>;
export type V1ServiceV1CardJamWorkspaceUnopenedCardPackListQueryResult<TData = V1ServiceV1CardJamWorkspaceUnopenedCardPackListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamWorkspaceUnopenedCardPackListKey = "V1ServiceV1CardJamWorkspaceUnopenedCardPackList";
export const UseV1ServiceV1CardJamWorkspaceUnopenedCardPackListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamWorkspaceUnopenedCardPackListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1CardJamUnopenedCardPackGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardJamUnopenedCardPackGet>>;
export type V1ServiceV1CardJamUnopenedCardPackGetQueryResult<TData = V1ServiceV1CardJamUnopenedCardPackGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardJamUnopenedCardPackGetKey = "V1ServiceV1CardJamUnopenedCardPackGet";
export const UseV1ServiceV1CardJamUnopenedCardPackGetKeyFn = ({ unopenedCardPackId, workspaceId }: {
  unopenedCardPackId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardJamUnopenedCardPackGetKey, ...(queryKey ?? [{ unopenedCardPackId, workspaceId }])];
export type V1ServiceV1OrderManagementFreeCardCouponListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCouponList>>;
export type V1ServiceV1OrderManagementFreeCardCouponListQueryResult<TData = V1ServiceV1OrderManagementFreeCardCouponListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementFreeCardCouponListKey = "V1ServiceV1OrderManagementFreeCardCouponList";
export const UseV1ServiceV1OrderManagementFreeCardCouponListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementFreeCardCouponListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1OrderManagementFreeCardCouponGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCouponGet>>;
export type V1ServiceV1OrderManagementFreeCardCouponGetQueryResult<TData = V1ServiceV1OrderManagementFreeCardCouponGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementFreeCardCouponGetKey = "V1ServiceV1OrderManagementFreeCardCouponGet";
export const UseV1ServiceV1OrderManagementFreeCardCouponGetKeyFn = ({ freeCardCouponId }: {
  freeCardCouponId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementFreeCardCouponGetKey, ...(queryKey ?? [{ freeCardCouponId }])];
export type V1ServiceV1OrderManagementOrderListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderList>>;
export type V1ServiceV1OrderManagementOrderListQueryResult<TData = V1ServiceV1OrderManagementOrderListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementOrderListKey = "V1ServiceV1OrderManagementOrderList";
export const UseV1ServiceV1OrderManagementOrderListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementOrderListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1OrderManagementOrderInternalGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderInternalGet>>;
export type V1ServiceV1OrderManagementOrderInternalGetQueryResult<TData = V1ServiceV1OrderManagementOrderInternalGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementOrderInternalGetKey = "V1ServiceV1OrderManagementOrderInternalGet";
export const UseV1ServiceV1OrderManagementOrderInternalGetKeyFn = ({ orderId }: {
  orderId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementOrderInternalGetKey, ...(queryKey ?? [{ orderId }])];
export type V1ServiceV1OrderManagementProductPriceListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceList>>;
export type V1ServiceV1OrderManagementProductPriceListQueryResult<TData = V1ServiceV1OrderManagementProductPriceListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementProductPriceListKey = "V1ServiceV1OrderManagementProductPriceList";
export const UseV1ServiceV1OrderManagementProductPriceListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementProductPriceListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1OrderManagementGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementGet>>;
export type V1ServiceV1OrderManagementGetQueryResult<TData = V1ServiceV1OrderManagementGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementGetKey = "V1ServiceV1OrderManagementGet";
export const UseV1ServiceV1OrderManagementGetKeyFn = ({ productType }: {
  productType: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementGetKey, ...(queryKey ?? [{ productType }])];
export type V1ServiceV1OrderManagementProductPriceReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceRead>>;
export type V1ServiceV1OrderManagementProductPriceReadQueryResult<TData = V1ServiceV1OrderManagementProductPriceReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementProductPriceReadKey = "V1ServiceV1OrderManagementProductPriceRead";
export const UseV1ServiceV1OrderManagementProductPriceReadKeyFn = ({ productPriceId }: {
  productPriceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementProductPriceReadKey, ...(queryKey ?? [{ productPriceId }])];
export type V1ServiceV1OrderManagementServiceOrderListGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementServiceOrderListGet>>;
export type V1ServiceV1OrderManagementServiceOrderListGetQueryResult<TData = V1ServiceV1OrderManagementServiceOrderListGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementServiceOrderListGetKey = "V1ServiceV1OrderManagementServiceOrderListGet";
export const UseV1ServiceV1OrderManagementServiceOrderListGetKeyFn = ({ active, created, createdGt, createdLt, id, marketingStatus, marketingStatusIn, marketingStatusNot, marketingStatusNotIn, orderByUserId, orderStatus, orderStatusIn, orderStatusNot, orderStatusNotIn, page, pageSize, workspaceId }: {
  active?: string;
  created?: string;
  createdGt?: string;
  createdLt?: string;
  id?: string;
  marketingStatus?: string;
  marketingStatusIn?: string;
  marketingStatusNot?: string;
  marketingStatusNotIn?: string;
  orderByUserId?: string;
  orderStatus?: string;
  orderStatusIn?: string;
  orderStatusNot?: string;
  orderStatusNotIn?: string;
  page?: number;
  pageSize?: number;
  workspaceId?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementServiceOrderListGetKey, ...(queryKey ?? [{ active, created, createdGt, createdLt, id, marketingStatus, marketingStatusIn, marketingStatusNot, marketingStatusNotIn, orderByUserId, orderStatus, orderStatusIn, orderStatusNot, orderStatusNotIn, page, pageSize, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceCartGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCartGet>>;
export type V1ServiceV1OrderManagementWorkspaceCartGetQueryResult<TData = V1ServiceV1OrderManagementWorkspaceCartGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceCartGetKey = "V1ServiceV1OrderManagementWorkspaceCartGet";
export const UseV1ServiceV1OrderManagementWorkspaceCartGetKeyFn = ({ freeCardsToUse, page, workspaceId }: {
  freeCardsToUse?: number;
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceCartGetKey, ...(queryKey ?? [{ freeCardsToUse, page, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceCartCartItemListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCartCartItemList>>;
export type V1ServiceV1OrderManagementWorkspaceCartCartItemListQueryResult<TData = V1ServiceV1OrderManagementWorkspaceCartCartItemListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceCartCartItemListKey = "V1ServiceV1OrderManagementWorkspaceCartCartItemList";
export const UseV1ServiceV1OrderManagementWorkspaceCartCartItemListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceCartCartItemListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceCartCartItemReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCartCartItemRead>>;
export type V1ServiceV1OrderManagementWorkspaceCartCartItemReadQueryResult<TData = V1ServiceV1OrderManagementWorkspaceCartCartItemReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceCartCartItemReadKey = "V1ServiceV1OrderManagementWorkspaceCartCartItemRead";
export const UseV1ServiceV1OrderManagementWorkspaceCartCartItemReadKeyFn = ({ cartItemId, workspaceId }: {
  cartItemId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceCartCartItemReadKey, ...(queryKey ?? [{ cartItemId, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceCheckoutStatusGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCheckoutStatusGet>>;
export type V1ServiceV1OrderManagementWorkspaceCheckoutStatusGetQueryResult<TData = V1ServiceV1OrderManagementWorkspaceCheckoutStatusGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceCheckoutStatusGetKey = "V1ServiceV1OrderManagementWorkspaceCheckoutStatusGet";
export const UseV1ServiceV1OrderManagementWorkspaceCheckoutStatusGetKeyFn = ({ orderId, workspaceId }: {
  orderId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceCheckoutStatusGetKey, ...(queryKey ?? [{ orderId, workspaceId }])];
export type V1ServiceV1OrderManagementFreeCardCountGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCountGet>>;
export type V1ServiceV1OrderManagementFreeCardCountGetQueryResult<TData = V1ServiceV1OrderManagementFreeCardCountGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementFreeCardCountGetKey = "V1ServiceV1OrderManagementFreeCardCountGet";
export const UseV1ServiceV1OrderManagementFreeCardCountGetKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementFreeCardCountGetKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceOrderListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderList>>;
export type V1ServiceV1OrderManagementWorkspaceOrderListQueryResult<TData = V1ServiceV1OrderManagementWorkspaceOrderListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceOrderListKey = "V1ServiceV1OrderManagementWorkspaceOrderList";
export const UseV1ServiceV1OrderManagementWorkspaceOrderListKeyFn = ({ page, workspaceId }: {
  page?: number;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceOrderListKey, ...(queryKey ?? [{ page, workspaceId }])];
export type V1ServiceV1OrderManagementWorkspaceOrderGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderGet>>;
export type V1ServiceV1OrderManagementWorkspaceOrderGetQueryResult<TData = V1ServiceV1OrderManagementWorkspaceOrderGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1OrderManagementWorkspaceOrderGetKey = "V1ServiceV1OrderManagementWorkspaceOrderGet";
export const UseV1ServiceV1OrderManagementWorkspaceOrderGetKeyFn = ({ orderId, workspaceId }: {
  orderId: string;
  workspaceId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1OrderManagementWorkspaceOrderGetKey, ...(queryKey ?? [{ orderId, workspaceId }])];
export type V1ServiceV1CardPrintingLocalPrintCardRequestListGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardPrintingLocalPrintCardRequestListGet>>;
export type V1ServiceV1CardPrintingLocalPrintCardRequestListGetQueryResult<TData = V1ServiceV1CardPrintingLocalPrintCardRequestListGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardPrintingLocalPrintCardRequestListGetKey = "V1ServiceV1CardPrintingLocalPrintCardRequestListGet";
export const UseV1ServiceV1CardPrintingLocalPrintCardRequestListGetKeyFn = ({ active, page, pageSize, printCardRequestStatus, printFinalizedTime, printFinalizedTimeGt, printFinalizedTimeLt }: {
  active?: string;
  page?: number;
  pageSize?: number;
  printCardRequestStatus?: string;
  printFinalizedTime?: string;
  printFinalizedTimeGt?: string;
  printFinalizedTimeLt?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1CardPrintingLocalPrintCardRequestListGetKey, ...(queryKey ?? [{ active, page, pageSize, printCardRequestStatus, printFinalizedTime, printFinalizedTimeGt, printFinalizedTimeLt }])];
export type V1ServiceV1CardPrintingPrintCardRequestReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1CardPrintingPrintCardRequestRead>>;
export type V1ServiceV1CardPrintingPrintCardRequestReadQueryResult<TData = V1ServiceV1CardPrintingPrintCardRequestReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1CardPrintingPrintCardRequestReadKey = "V1ServiceV1CardPrintingPrintCardRequestRead";
export const UseV1ServiceV1CardPrintingPrintCardRequestReadKeyFn = ({ printCardRequestId }: {
  printCardRequestId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1CardPrintingPrintCardRequestReadKey, ...(queryKey ?? [{ printCardRequestId }])];
export type V1ServiceTeamInternalSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.teamInternalSearchGet>>;
export type V1ServiceTeamInternalSearchGetQueryResult<TData = V1ServiceTeamInternalSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceTeamInternalSearchGetKey = "V1ServiceTeamInternalSearchGet";
export const UseV1ServiceTeamInternalSearchGetKeyFn = ({ limit, offset, term }: {
  limit?: number;
  offset?: number;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceTeamInternalSearchGetKey, ...(queryKey ?? [{ limit, offset, term }])];
export type V1ServiceTeamSearchGetDefaultResponse = Awaited<ReturnType<typeof V1Service.teamSearchGet>>;
export type V1ServiceTeamSearchGetQueryResult<TData = V1ServiceTeamSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceTeamSearchGetKey = "V1ServiceTeamSearchGet";
export const UseV1ServiceTeamSearchGetKeyFn = ({ limit, offset, term }: {
  limit?: number;
  offset?: number;
  term?: string;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceTeamSearchGetKey, ...(queryKey ?? [{ limit, offset, term }])];
export type V1ServiceV1TeamManagementInviteReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementInviteRead>>;
export type V1ServiceV1TeamManagementInviteReadQueryResult<TData = V1ServiceV1TeamManagementInviteReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementInviteReadKey = "V1ServiceV1TeamManagementInviteRead";
export const UseV1ServiceV1TeamManagementInviteReadKeyFn = ({ inviteId }: {
  inviteId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementInviteReadKey, ...(queryKey ?? [{ inviteId }])];
export type V1ServiceV1TeamManagementMemberListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementMemberList>>;
export type V1ServiceV1TeamManagementMemberListQueryResult<TData = V1ServiceV1TeamManagementMemberListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementMemberListKey = "V1ServiceV1TeamManagementMemberList";
export const UseV1ServiceV1TeamManagementMemberListKeyFn = ({ page, userId }: {
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementMemberListKey, ...(queryKey ?? [{ page, userId }])];
export type V1ServiceV1TeamManagementTeamListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamList>>;
export type V1ServiceV1TeamManagementTeamListQueryResult<TData = V1ServiceV1TeamManagementTeamListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamListKey = "V1ServiceV1TeamManagementTeamList";
export const UseV1ServiceV1TeamManagementTeamListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamListKey, ...(queryKey ?? [{ page }])];
export type V1ServiceV1TeamManagementTeamMemberIdGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberIdGet>>;
export type V1ServiceV1TeamManagementTeamMemberIdGetQueryResult<TData = V1ServiceV1TeamManagementTeamMemberIdGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamMemberIdGetKey = "V1ServiceV1TeamManagementTeamMemberIdGet";
export const UseV1ServiceV1TeamManagementTeamMemberIdGetKeyFn = ({ teamMemberId }: {
  teamMemberId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamMemberIdGetKey, ...(queryKey ?? [{ teamMemberId }])];
export type V1ServiceV1TeamManagementTeamReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamRead>>;
export type V1ServiceV1TeamManagementTeamReadQueryResult<TData = V1ServiceV1TeamManagementTeamReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamReadKey = "V1ServiceV1TeamManagementTeamRead";
export const UseV1ServiceV1TeamManagementTeamReadKeyFn = ({ teamId }: {
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamReadKey, ...(queryKey ?? [{ teamId }])];
export type V1ServiceV1TeamManagementTeamInviteListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInviteList>>;
export type V1ServiceV1TeamManagementTeamInviteListQueryResult<TData = V1ServiceV1TeamManagementTeamInviteListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamInviteListKey = "V1ServiceV1TeamManagementTeamInviteList";
export const UseV1ServiceV1TeamManagementTeamInviteListKeyFn = ({ page, teamId }: {
  page?: number;
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamInviteListKey, ...(queryKey ?? [{ page, teamId }])];
export type V1ServiceV1TeamManagementTeamInviteReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInviteRead>>;
export type V1ServiceV1TeamManagementTeamInviteReadQueryResult<TData = V1ServiceV1TeamManagementTeamInviteReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamInviteReadKey = "V1ServiceV1TeamManagementTeamInviteRead";
export const UseV1ServiceV1TeamManagementTeamInviteReadKeyFn = ({ inviteId, teamId }: {
  inviteId: string;
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamInviteReadKey, ...(queryKey ?? [{ inviteId, teamId }])];
export type V1ServiceV1TeamManagementTeamMemberListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberList>>;
export type V1ServiceV1TeamManagementTeamMemberListQueryResult<TData = V1ServiceV1TeamManagementTeamMemberListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamMemberListKey = "V1ServiceV1TeamManagementTeamMemberList";
export const UseV1ServiceV1TeamManagementTeamMemberListKeyFn = ({ page, teamId }: {
  page?: number;
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamMemberListKey, ...(queryKey ?? [{ page, teamId }])];
export type V1ServiceV1TeamManagementTeamMemberReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberRead>>;
export type V1ServiceV1TeamManagementTeamMemberReadQueryResult<TData = V1ServiceV1TeamManagementTeamMemberReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamMemberReadKey = "V1ServiceV1TeamManagementTeamMemberRead";
export const UseV1ServiceV1TeamManagementTeamMemberReadKeyFn = ({ teamId, userId }: {
  teamId: string;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamMemberReadKey, ...(queryKey ?? [{ teamId, userId }])];
export type V1ServiceV1TeamManagementTeamMemberGetDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberGet>>;
export type V1ServiceV1TeamManagementTeamMemberGetQueryResult<TData = V1ServiceV1TeamManagementTeamMemberGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamMemberGetKey = "V1ServiceV1TeamManagementTeamMemberGet";
export const UseV1ServiceV1TeamManagementTeamMemberGetKeyFn = ({ page, teamId, userId }: {
  page?: number;
  teamId: string;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamMemberGetKey, ...(queryKey ?? [{ page, teamId, userId }])];
export type V1ServiceV1TeamManagementTeamTeamCardListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamTeamCardList>>;
export type V1ServiceV1TeamManagementTeamTeamCardListQueryResult<TData = V1ServiceV1TeamManagementTeamTeamCardListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamTeamCardListKey = "V1ServiceV1TeamManagementTeamTeamCardList";
export const UseV1ServiceV1TeamManagementTeamTeamCardListKeyFn = ({ page, teamId }: {
  page?: number;
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamTeamCardListKey, ...(queryKey ?? [{ page, teamId }])];
export type V1ServiceV1TeamManagementTeamTeamCardReadDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamTeamCardRead>>;
export type V1ServiceV1TeamManagementTeamTeamCardReadQueryResult<TData = V1ServiceV1TeamManagementTeamTeamCardReadDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementTeamTeamCardReadKey = "V1ServiceV1TeamManagementTeamTeamCardRead";
export const UseV1ServiceV1TeamManagementTeamTeamCardReadKeyFn = ({ teamCardId, teamId }: {
  teamCardId: string;
  teamId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementTeamTeamCardReadKey, ...(queryKey ?? [{ teamCardId, teamId }])];
export type V1ServiceV1TeamManagementUserPermissionListDefaultResponse = Awaited<ReturnType<typeof V1Service.v1TeamManagementUserPermissionList>>;
export type V1ServiceV1TeamManagementUserPermissionListQueryResult<TData = V1ServiceV1TeamManagementUserPermissionListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useV1ServiceV1TeamManagementUserPermissionListKey = "V1ServiceV1TeamManagementUserPermissionList";
export const UseV1ServiceV1TeamManagementUserPermissionListKeyFn = ({ page, userId }: {
  page?: number;
  userId: string;
}, queryKey?: Array<unknown>) => [useV1ServiceV1TeamManagementUserPermissionListKey, ...(queryKey ?? [{ page, userId }])];
export type V1ServiceV1CommunicationEmailTemplateCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplateCreate>>;
export type V1ServiceV1CommunicationMfaCodeResendCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationMfaCodeResendCreate>>;
export type V1ServiceV1CommunicationMfaCodeVerifyCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationMfaCodeVerifyCreate>>;
export type V1ServiceV1CommunicationMfaCodeCreateCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationMfaCodeCreateCreate>>;
export type V1ServiceV1CommunicationTextTemplateCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplateCreate>>;
export type V1ServiceV1UserManagementAccountOpsAccountMergePostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementAccountOpsAccountMergePost>>;
export type V1ServiceV1UserManagementAccountOpsDataRetentionPostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementAccountOpsDataRetentionPost>>;
export type V1ServiceV1UserManagementUserDataRetentionUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserDataRetentionUpdatePost>>;
export type V1ServiceV1UserManagementUserMarketingStatusUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserMarketingStatusUpdatePost>>;
export type V1ServiceV1UserManagementApiLoginCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementApiLoginCreate>>;
export type V1ServiceV1UserManagementExternalServiceUsersCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementExternalServiceUsersCreate>>;
export type V1ServiceV1UserManagementGuestWorkspaceLoginCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementGuestWorkspaceLoginCreate>>;
export type V1ServiceV1UserManagementGuestWorkspaceMfaLoginCompleteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementGuestWorkspaceMfaLoginCompleteCreate>>;
export type V1ServiceV1UserManagementLoginCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementLoginCreate>>;
export type V1ServiceV1UserManagementLogoutCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementLogoutCreate>>;
export type V1ServiceV1UserManagementMfaLoginCompleteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementMfaLoginCompleteCreate>>;
export type V1ServiceV1UserManagementMfaLoginSendCodeCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementMfaLoginSendCodeCreate>>;
export type V1ServiceV1UserManagementMfaLoginStartCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementMfaLoginStartCreate>>;
export type V1ServiceV1UserManagementMfaLoginResendCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementMfaLoginResendCreate>>;
export type V1ServiceV1UserManagementPoliciesAgreementCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesAgreementCreate>>;
export type V1ServiceV1UserManagementPoliciesUploadCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementPoliciesUploadCreate>>;
export type V1ServiceV1UserManagementServiceUsersCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementServiceUsersCreate>>;
export type V1ServiceV1UserManagementChildUserRegistrationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementChildUserRegistrationPost>>;
export type V1ServiceV1UserManagementTokenOrganizationRefreshCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementTokenOrganizationRefreshCreate>>;
export type V1ServiceV1UserManagementTokenRefreshCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementTokenRefreshCreate>>;
export type V1ServiceV1UserManagementUserWorkspacePublicProfilesCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspacePublicProfilesCreate>>;
export type V1ServiceV1UserManagementUserWorkspacePostMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspacePost>>;
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspaceUserPiiDataEntryCreate>>;
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryUploadCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspaceUserPiiDataEntryUploadCreate>>;
export type V1ServiceV1UserManagementUsersEmailVerificationConfirmCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersEmailVerificationConfirmCreate>>;
export type V1ServiceV1UserManagementUsersEmailVerificationRequestCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersEmailVerificationRequestCreate>>;
export type V1ServiceUserManagementGuestUserRegistrationPostMutationResult = Awaited<ReturnType<typeof V1Service.userManagementGuestUserRegistrationPost>>;
export type V1ServiceUserManagementGuestUserRegistrationCompletePostMutationResult = Awaited<ReturnType<typeof V1Service.userManagementGuestUserRegistrationCompletePost>>;
export type V1ServiceV1UserManagementUsersPasswordConfirmCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersPasswordConfirmCreate>>;
export type V1ServiceV1UserManagementUsersPasswordResetCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersPasswordResetCreate>>;
export type V1ServiceV1UserManagementUsersPublicProfilesCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersPublicProfilesCreate>>;
export type V1ServiceV1UserManagementUsersRegistrationCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersRegistrationCreate>>;
export type V1ServiceV1UserManagementUsersRegistrationUsernameAvailableCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersRegistrationUsernameAvailableCreate>>;
export type V1ServiceV1UserManagementUsersVerifyPhoneResendCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersVerifyPhoneResendCreate>>;
export type V1ServiceV1UserManagementUsersVerifyPhoneVerifyCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersVerifyPhoneVerifyCreate>>;
export type V1ServiceV1UserManagementUsersEmailCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersEmailCreate>>;
export type V1ServiceUserManagementGuestUsersChangeEmailPostMutationResult = Awaited<ReturnType<typeof V1Service.userManagementGuestUsersChangeEmailPost>>;
export type V1ServiceV1UserManagementUsersPasswordCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersPasswordCreate>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryCreate>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryUploadCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryUploadCreate>>;
export type V1ServiceV1UserManagementUsersVerifyPhoneStartCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersVerifyPhoneStartCreate>>;
export type V1ServiceV1OrganizationManagementAccountOpsDataRetentionPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementAccountOpsDataRetentionPost>>;
export type V1ServiceV1OrganizationManagementAccountOpsPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementAccountOpsPost>>;
export type V1ServiceV1OrganizationManagementGuestFamilyRegistrationCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementGuestFamilyRegistrationCreatePost>>;
export type V1ServiceV1OrganizationManagementGuestFamilyRegistrationCompletePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementGuestFamilyRegistrationCompletePost>>;
export type V1ServiceV1OrganizationManagementInviteAcceptCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementInviteAcceptCreate>>;
export type V1ServiceV1OrganizationManagementPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementPost>>;
export type V1ServiceV1OrganizationManagementOrganizationCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationCreate>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspacePiiBulkDataEntryListPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspacePiiBulkDataEntryListPost>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspacePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspacePost>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryCreate>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryUploadCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryUploadCreate>>;
export type V1ServiceV1OrganizationManagementOrganizationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationPost>>;
export type V1ServiceV1OrganizationManagementOrganizationInviteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInviteCreate>>;
export type V1ServiceV1OrganizationManagementOrganizationInvitePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInvitePost>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberChildPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberChildPost>>;
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOrganizationPiiDataEntryCreate>>;
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryUploadCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOrganizationPiiDataEntryUploadCreate>>;
export type V1ServiceV1ApiUserOrganizationCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1ApiUserOrganizationCreate>>;
export type V1ServiceV1CardJamCardPackCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardPackCreate>>;
export type V1ServiceV1CardJamCardSetCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetCreate>>;
export type V1ServiceV1CardJamCardSetPackCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackCreate>>;
export type V1ServiceV1CardJamCardSetPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPost>>;
export type V1ServiceV1CardJamCardVariantAttributeCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeCreate>>;
export type V1ServiceV1CardJamCardVariantAttributeRawPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeRawPost>>;
export type V1ServiceV1CardJamCardVariantAttributeUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeUpdatePost>>;
export type V1ServiceV1CardJamServiceCardsDownloadPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamServiceCardsDownloadPost>>;
export type V1ServiceV1CardJamLocalAdminPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamLocalAdminPost>>;
export type V1ServiceV1CardJamMarketingCampaignCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignCreate>>;
export type V1ServiceV1CardJamMarketingCampaignActivatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignActivatePost>>;
export type V1ServiceV1CardJamMarketingCampaignCardPackCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignCardPackCreatePost>>;
export type V1ServiceV1CardJamMarketingCampaignDeactivatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignDeactivatePost>>;
export type V1ServiceV1CardJamWorkspaceCardCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardCreate>>;
export type V1ServiceV1CardJamWorkspaceCardAttributeCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardAttributeCreate>>;
export type V1ServiceV1CardJamCardAttributeDecreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardAttributeDecreasePost>>;
export type V1ServiceV1CardJamCardAttributeIncreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardAttributeIncreasePost>>;
export type V1ServiceV1CardJamWorkspaceCardDesignCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardDesignCreate>>;
export type V1ServiceV1CardJamCardDesignDecreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardDesignDecreasePost>>;
export type V1ServiceV1CardJamCardDesignIncreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardDesignIncreasePost>>;
export type V1ServiceV1CardJamWorkspaceCardFullCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardFullCreatePost>>;
export type V1ServiceV1CardJamWorkspaceCardInternalCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardInternalCreatePost>>;
export type V1ServiceV1CardJamWorkspaceCardFinalizePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardFinalizePost>>;
export type V1ServiceV1CardJamWorkspaceCardUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardUpdatePost>>;
export type V1ServiceV1CardJamWorkspaceCardVariantCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardVariantCreatePost>>;
export type V1ServiceV1CardJamWorkspacePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspacePost>>;
export type V1ServiceV1CardJamWorkspaceUnopenedCardPackCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceUnopenedCardPackCreate>>;
export type V1ServiceV1CardJamUnopenedCardPackDecreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamUnopenedCardPackDecreasePost>>;
export type V1ServiceV1CardJamUnopenedCardPackIncreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamUnopenedCardPackIncreasePost>>;
export type V1ServiceV1CardJamOpenUnopenedCardPackPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamOpenUnopenedCardPackPost>>;
export type V1ServiceV1OrderManagementAbandonedCartReminderPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementAbandonedCartReminderPost>>;
export type V1ServiceV1OrderManagementOrderMarketingStatusUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderMarketingStatusUpdatePost>>;
export type V1ServiceV1OrderManagementFreeCardCouponCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCouponCreatePost>>;
export type V1ServiceV1OrderManagementOrderInternalPrintingStartedBulkPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderInternalPrintingStartedBulkPost>>;
export type V1ServiceV1OrderManagementOrderInternalShippedBulkPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderInternalShippedBulkPost>>;
export type V1ServiceV1OrderManagementOrderInternalPrintingStartedPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderInternalPrintingStartedPost>>;
export type V1ServiceV1OrderManagementOrderInternalShippedPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderInternalShippedPost>>;
export type V1ServiceV1OrderManagementProductPriceCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceCreate>>;
export type V1ServiceV1OrderManagementAccountOpsAccountMergePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementAccountOpsAccountMergePost>>;
export type V1ServiceV1OrderManagementAccountOpsDataRetentionPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementAccountOpsDataRetentionPost>>;
export type V1ServiceV1OrderManagementWorkspaceOrderStatisticsPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderStatisticsPost>>;
export type V1ServiceV1OrderManagementWorkspaceCheckoutTimeoutPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCheckoutTimeoutPost>>;
export type V1ServiceV1OrderManagementServiceWorkspaceOrderSendConfirmationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementServiceWorkspaceOrderSendConfirmationPost>>;
export type V1ServiceV1OrderManagementServiceWorkspaceOrderUpdateSendConfirmationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementServiceWorkspaceOrderUpdateSendConfirmationPost>>;
export type V1ServiceV1OrderManagementStripeEventRetryPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementStripeEventRetryPost>>;
export type V1ServiceV1OrderManagementStripeWebhookPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementStripeWebhookPost>>;
export type V1ServiceV1OrderManagementWorkspaceCartCartItemPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCartCartItemPost>>;
export type V1ServiceV1OrderManagementCartItemTeamCardPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementCartItemTeamCardPost>>;
export type V1ServiceV1OrderManagementCartItemTeamPackPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementCartItemTeamPackPost>>;
export type V1ServiceV1OrderManagementWorkspaceCheckoutStartPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCheckoutStartPost>>;
export type V1ServiceV1OrderManagementWorkspaceCheckoutCancelPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCheckoutCancelPost>>;
export type V1ServiceV1OrderManagementWorkspaceCheckoutCompletePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCheckoutCompletePost>>;
export type V1ServiceV1OrderManagementFreeCardCountDecreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCountDecreasePost>>;
export type V1ServiceV1OrderManagementFreeCardCountIncreasePostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCountIncreasePost>>;
export type V1ServiceV1OrderManagementFreeCardCouponClaimPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCouponClaimPost>>;
export type V1ServiceV1OrderManagementWorkspaceOrderSendConfirmationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderSendConfirmationPost>>;
export type V1ServiceV1OrderManagementWorkspaceOrderUpdateSendConfirmationPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderUpdateSendConfirmationPost>>;
export type V1ServiceV1OrderManagementOrderRewardClaimPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderRewardClaimPost>>;
export type V1ServiceV1OrderManagementOrderUpdateCardsPostMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementOrderUpdateCardsPost>>;
export type V1ServiceV1CardPrintingLocalPrintCardRequestCompletePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingLocalPrintCardRequestCompletePost>>;
export type V1ServiceV1CardPrintingOrderPrintCardRequestCreatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingOrderPrintCardRequestCreatePost>>;
export type V1ServiceV1CardPrintingOrderPrintCardRequestUpdatePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingOrderPrintCardRequestUpdatePost>>;
export type V1ServiceV1CardPrintingPostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingPost>>;
export type V1ServiceV1CardPrintingAccountOpsAccountMergePostMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingAccountOpsAccountMergePost>>;
export type V1ServiceV1AccountOpsDataRetentionRunStartPostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsDataRetentionRunStartPost>>;
export type V1ServiceV1AccountOpsDataRetentionRunClearPagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsDataRetentionRunClearPagePost>>;
export type V1ServiceV1AccountOpsDataRetentionRunCompletePagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsDataRetentionRunCompletePagePost>>;
export type V1ServiceV1AccountOpsGuestLoginCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestLoginCreate>>;
export type V1ServiceV1AccountOpsGuestMfaLoginCompleteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestMfaLoginCompleteCreate>>;
export type V1ServiceV1AccountOpsGuestMfaLoginSendCodeCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestMfaLoginSendCodeCreate>>;
export type V1ServiceV1AccountOpsGuestMfaLoginStartCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestMfaLoginStartCreate>>;
export type V1ServiceV1AccountOpsGuestMfaLoginResendCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestMfaLoginResendCreate>>;
export type V1ServiceV1AccountOpsGuestWorkspaceLoginCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestWorkspaceLoginCreate>>;
export type V1ServiceV1AccountOpsGuestWorkspaceMfaLoginCompleteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsGuestWorkspaceMfaLoginCompleteCreate>>;
export type V1ServiceV1AccountOpsMarketingSyncRunStartPostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsMarketingSyncRunStartPost>>;
export type V1ServiceV1AccountOpsMarketingSyncRunCompletePagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsMarketingSyncRunCompletePagePost>>;
export type V1ServiceV1AccountOpsMarketingSyncRunClearPagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsMarketingSyncRunClearPagePost>>;
export type V1ServiceV1AccountOpsOrderMarketingSyncRunStartPostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsOrderMarketingSyncRunStartPost>>;
export type V1ServiceV1AccountOpsOrderMarketingSyncRunCompletePagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsOrderMarketingSyncRunCompletePagePost>>;
export type V1ServiceV1AccountOpsOrderMarketingSyncRunClearPagePostMutationResult = Awaited<ReturnType<typeof V1Service.v1AccountOpsOrderMarketingSyncRunClearPagePost>>;
export type V1ServiceV1TeamManagementInviteAcceptCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementInviteAcceptCreate>>;
export type V1ServiceV1TeamManagementServicePostMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementServicePost>>;
export type V1ServiceV1TeamManagementTeamCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamCreate>>;
export type V1ServiceV1TeamManagementTeamPostMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamPost>>;
export type V1ServiceV1TeamManagementTeamInviteCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInviteCreate>>;
export type V1ServiceV1TeamManagementTeamInvitePostMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInvitePost>>;
export type V1ServiceV1TeamManagementTeamTeamCardCreateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamTeamCardCreate>>;
export type V1ServiceV1CommunicationEmailTemplateUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplateUpdate>>;
export type V1ServiceV1CommunicationTextTemplateUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplateUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationOwnerUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOwnerUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationTagsUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationTagsUpdate>>;
export type V1ServiceV1TeamManagementTeamOwnerUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamOwnerUpdate>>;
export type V1ServiceV1CommunicationEmailTemplatePartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplatePartialUpdate>>;
export type V1ServiceV1CommunicationTextTemplatePartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplatePartialUpdate>>;
export type V1ServiceV1UserManagementExternalServiceUsersPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementExternalServiceUsersPartialUpdate>>;
export type V1ServiceV1UserManagementUserWorkspaceUserPiiDataEntryPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUserWorkspaceUserPiiDataEntryPartialUpdate>>;
export type V1ServiceV1UserManagementUsersPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersPartialUpdate>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryPartialUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspaceOrganizationPiiDataEntryPartialUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationPartialUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberPartialUpdate>>;
export type V1ServiceV1OrganizationManagementOrganizationOrganizationPiiDataEntryPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationOrganizationPiiDataEntryPartialUpdate>>;
export type V1ServiceV1OrderManagementProductPriceUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceUpdate>>;
export type V1ServiceV1OrderManagementWorkspaceCartCartItemUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceCartCartItemUpdate>>;
export type V1ServiceV1CardPrintingPrintCardRequestPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingPrintCardRequestPartialUpdate>>;
export type V1ServiceV1TeamManagementTeamPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamPartialUpdate>>;
export type V1ServiceV1TeamManagementTeamMemberPartialUpdateMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberPartialUpdate>>;
export type V1ServiceV1CommunicationEmailTemplateDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationEmailTemplateDelete>>;
export type V1ServiceV1CommunicationTextTemplateDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CommunicationTextTemplateDelete>>;
export type V1ServiceV1UserManagementExternalServiceUsersDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementExternalServiceUsersDelete>>;
export type V1ServiceUserManagementExternalServiceUsersHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.userManagementExternalServiceUsersHardDelete>>;
export type V1ServiceV1UserManagementUsersUserWorkspacePiiDataEntryListDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserWorkspacePiiDataEntryListDelete>>;
export type V1ServiceV1UserManagementUsersUserWorkspacePiiDataEntryDetailDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserWorkspacePiiDataEntryDetailDelete>>;
export type V1ServiceV1UserManagementUsersDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersDelete>>;
export type V1ServiceV1UserManagementUsersHardDeleteDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersHardDeleteDelete>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryListDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryListDelete>>;
export type V1ServiceV1UserManagementUsersUserPiiDataEntryDetailDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1UserManagementUsersUserPiiDataEntryDetailDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberIdDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberIdDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspacePiiDataEntryListDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspacePiiDataEntryListDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationWorkspacePiiDataEntryDetailDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationWorkspacePiiDataEntryDetailDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationHardDeleteDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationHardDeleteDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationInviteDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInviteDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationInviteHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationInviteHardDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationMemberDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationMemberDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationPiiDataEntryListDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationPiiDataEntryListDelete>>;
export type V1ServiceV1OrganizationManagementOrganizationPiiDataEntryDetailDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrganizationManagementOrganizationPiiDataEntryDetailDelete>>;
export type V1ServiceV1ApiUserApiUserByUserIdDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1ApiUserApiUserByUserIdDelete>>;
export type V1ServiceV1ApiApiUserHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1ApiApiUserHardDelete>>;
export type V1ServiceV1ApiUserOrganizationApiUserDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1ApiUserOrganizationApiUserDelete>>;
export type V1ServiceV1ApiUserOrganizationApiUserHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1ApiUserOrganizationApiUserHardDelete>>;
export type V1ServiceV1CardJamCardPackDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardPackDelete>>;
export type V1ServiceV1CardJamCardPackHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardPackHardDelete>>;
export type V1ServiceV1CardJamCardSetPackDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackDelete>>;
export type V1ServiceV1CardJamCardSetPackHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetPackHardDelete>>;
export type V1ServiceV1CardJamCardSetDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetDelete>>;
export type V1ServiceV1CardJamCardSetHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardSetHardDelete>>;
export type V1ServiceV1CardJamCardVariantAttributeHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardVariantAttributeHardDelete>>;
export type V1ServiceV1CardJamMarketingCampaignCardPackDeleteHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignCardPackDeleteHardDelete>>;
export type V1ServiceV1CardJamMarketingCampaignHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamMarketingCampaignHardDelete>>;
export type V1ServiceV1CardJamCardAttributeHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardAttributeHardDelete>>;
export type V1ServiceV1CardJamCardDesignHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamCardDesignHardDelete>>;
export type V1ServiceV1CardJamWorkspaceCardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamWorkspaceCardDelete>>;
export type V1ServiceV1CardJamUnopenedCardPackHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardJamUnopenedCardPackHardDelete>>;
export type V1ServiceV1OrderManagementFreeCardCouponDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCouponDelete>>;
export type V1ServiceV1OrderManagementProductPriceDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceDelete>>;
export type V1ServiceV1OrderManagementProductPriceHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementProductPriceHardDelete>>;
export type V1ServiceV1OrderManagementCartItemHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementCartItemHardDelete>>;
export type V1ServiceV1OrderManagementFreeCardCountHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementFreeCardCountHardDelete>>;
export type V1ServiceV1OrderManagementWorkspaceOrderDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1OrderManagementWorkspaceOrderDelete>>;
export type V1ServiceV1CardPrintingPrintCardRequestDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1CardPrintingPrintCardRequestDelete>>;
export type V1ServiceV1TeamManagementTeamMemberIdDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberIdDelete>>;
export type V1ServiceV1TeamManagementTeamDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamDelete>>;
export type V1ServiceV1TeamManagementTeamHardDeleteDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamHardDeleteDelete>>;
export type V1ServiceV1TeamManagementTeamInviteDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInviteDelete>>;
export type V1ServiceV1TeamManagementTeamInviteHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamInviteHardDelete>>;
export type V1ServiceV1TeamManagementTeamMemberDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamMemberDelete>>;
export type V1ServiceV1TeamManagementTeamCardHardDeleteMutationResult = Awaited<ReturnType<typeof V1Service.v1TeamManagementTeamCardHardDelete>>;
